import React from 'react';
import { Desktop, Mobile } from '@components/Layout';
import Image from 'next/image';
import styles from './css';

const CampaignTopHeroSection = () => {
  const alt = '日本へ、世界へ、ふらっと、でかける';

  return (
    <section className="campaign-top-hero-section">
      <div className="campaign-hero-content">
        <Desktop>
          <Image
            alt={alt}
            height={475}
            src="/images/jp-top/hero-desktop.png"
            style={{
              height: 'auto',
              width: '100%',
            }}
            width={1440}
          />
        </Desktop>
        <Mobile>
          <Image
            alt={alt}
            height={745}
            src="/images/jp-top/hero-mobile.png"
            style={{
              height: 'auto',
              width: '100%',
            }}
            width={745}
          />
        </Mobile>
      </div>
      <div className="message-wrapper">
        <Desktop>
          <Image
            alt="HafHで、旅を日常に取り入れてみませんか。"
            height={432}
            src="/images/introduction-message/message-desktop.png"
            style={{
              height: 'auto',
              maxWidth: 490,
              width: '100%',
            }}
            width={490}
          />
        </Desktop>
        <Mobile>
          <Image
            alt="HafHで、旅を日常に取り入れてみませんか。"
            height={480}
            src="/images/introduction-message/message-mobile.png"
            style={{
              height: 'auto',
              maxWidth: 343,
              width: '100%',
            }}
            width={343}
          />
        </Mobile>
      </div>
      <style jsx={true}>{styles}</style>
    </section>
  );
};

export default CampaignTopHeroSection;
