import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';
import css from 'styled-jsx/css';

const { mobile } = BREAKPOINT;

const styles = css`
  .tabs {
    width: 100%;
    white-space: nowrap;

    @media screen and (max-width: ${mobile}px) {
      white-space: pre-wrap;
    }
  }

  ul {
    display: flex;
    justify-content: space-evenly;
  }

  .tab-item {
    display: flex;
    margin-left: 0;
    transition: color 0.3s ease;
    cursor: default;
    color: ${COLORS.black50};
    position: relative;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      border-bottom: 2px solid transparent;
      bottom: 0;
      transition: border 0.3s ease;
    }

    &.selected {
      color: ${COLORS.black};

      &::before {
        border-color: ${COLORS.black};
      }

      button {
        font-weight: bold;
      }
    }

    button {
      width: 100%;
      padding: 14px 10px;
      font-size: 14px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  /* For coin modifier */
  .tabs-coin-history {
    .tab-list-cont {
      display: flex;
      justify-content: center;
    }
    .tab-list {
      display: inline-flex;
      margin-bottom: 16px;
    }

    .tab-item {
      width: initial;
      &:nth-of-type(1) {
        margin-right: 8px;
      }
      button {
        padding: 8px 16px;
      }
      &.selected {
        border-radius: 20px;
        background: ${COLORS.black300};
        &:before {
          display: none;
        }
      }
    }
  }
`;

export default styles;
