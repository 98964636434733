import React from 'react';
import Collapse from '@atoms/Collapse';
import SectionHeader from '@atoms/SectionHeader';
import Link from '@components/Link';
import useLocation from '@hooks/useLocation';
import { ZENDESK_LINK } from '@utils/constants';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

/**
 * https://www.notion.so/kabukstyle/Update-FAQ-on-hafh-com-8ebbbf6c48804ea3a4660690ea73627f
 * Below conditions are used this format price function.
 * 1. English for Korean product
 * 2. English for global product
 * Because other conditions have own hard coded coin description of Q4.
 *
 * FYI: JP doesn't use this Component but uses indicated JpTopFaq Component
 * https://github.com/kabuk-style/monorepo/tree/master/apps/web/src/components/molecules/JpTopFaq
 *
 */
const formatPrice = ({ isKorea, value }: { isKorea: boolean; value: number }) =>
  new Intl.NumberFormat(isKorea ? 'ko-KR' : 'en-US', {
    currency: isKorea ? 'KRW' : 'USD',
    currencyDisplay: 'code',
    style: 'currency',
  }).format(value);

const COIN_MAP = {
  coin: { glb: 25, ko: 33_000 },
  maxFirstMonth: { glb: 250, ko: 330_000 },
  maxPerMonth: { glb: 1500, ko: 2_000_000 },
};

const TopFaq = () => {
  const { t } = useTranslation('top');
  const { isKorea } = useLocation();

  const coin = formatPrice({
    isKorea,
    value: isKorea ? COIN_MAP.coin.ko : COIN_MAP.coin.glb,
  });
  const maxPerMonthCoin = formatPrice({
    isKorea,
    value: isKorea ? COIN_MAP.maxPerMonth.ko : COIN_MAP.maxPerMonth.glb,
  });

  return (
    <div className="top-faq-wrapper">
      <div className="top-faq">
        <div className="top-faq-header">
          <SectionHeader heading={t('faqSection.heading')} />
          {/* TODO: CHANGE URL */}
          <Link href={ZENDESK_LINK}>
            <span className="view-all-lint">{t('faqSection.viewAll')}</span>
          </Link>
        </div>
        <div className="top-faq-collapse-wrapper">
          {[1, 2, 3, 4].map((index) => (
            <Collapse
              key={index}
              iconType="arrow"
              noBorder={true}
              title={t(`faqSection.faq${index}.question`)}
            >
              <p className="faq-content">
                {t(`faqSection.faq${index}.answer`, {
                  coin,
                  maxPerMonthCoin,
                })}
                {isKorea && index === 4 ? t('faqSection.faq4.remarks') : ''}
              </p>
            </Collapse>
          ))}
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default TopFaq;
