import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import styles from './css';

type Props = {
  children: ReactNode[];
  modifier?: string;
  onClick?: (index: number) => void;
  selected?: number;
  tabTitles: ReactNode[] | string[];
};

const Tabs = ({
  children,
  modifier,
  onClick,
  selected = 0,
  tabTitles,
}: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(selected);

  const onClickButton = (index: number) => {
    setSelectedIndex(index);

    if (onClick) {
      onClick(index);
    }
  };

  return (
    <div className={classNames('tabs', { [`tabs-${modifier}`]: modifier })}>
      <div className="tab-list-cont">
        <ul className="tab-list">
          {tabTitles.map((tabTitle, index) => (
            <li
              key={typeof tabTitle === 'object' ? tabTitle.key : tabTitle}
              className={classNames('tab-item', {
                selected: index === selectedIndex,
              })}
            >
              <button onClick={() => onClickButton(index)} type="button">
                {tabTitle}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="tab">{children[selectedIndex]}</div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default Tabs;
